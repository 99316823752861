import { gsap } from "gsap";
import { TweenMax, ScrollToPlugin, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export default class PageBusiness {
  constructor() {
    this.container = document.getElementsByClassName("js-page-business")[0];
    this.init();
  }

  init() {
    if (!this.container) return false;

    this.bindEvents();
  }

  bindEvents() {
    // more btn
    const moreBtnElms = this.container.getElementsByClassName("js-business-more-btn");

    if (moreBtnElms.length) {
      Array.prototype.forEach.call(moreBtnElms, elm => {
        elm.addEventListener("click", e => {
          e.preventDefault();
          if (elm.classList.contains("-active")) {
            elm.classList.remove("-active");
          } else {
            elm.classList.add("-active");
          }
        });
      });
    }

    // 特定のスクロール位置で「-active」クラスを追加
    const bgTargetElms = this.container.getElementsByClassName("js-business-bg-elm");
    if (bgTargetElms.length) {
      Array.prototype.forEach.call(bgTargetElms, (elm, id) => {
        ScrollTrigger.create({
          trigger: elm,
          id: id+1,
          start: 'top center',
          toggleClass: {
            targets: elm,
            className: '-active'
          },
          markers: false,
        });
      });
    }
  }
}
