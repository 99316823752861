import { gsap } from "gsap";
import { TweenMax, ScrollToPlugin, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export default class PageBg {
  constructor() {
    this.container = document.getElementsByClassName("js-page-bg")[0];
    this.init();
  }

  init() {
    if (!this.container) return false;

    this.bindEvents();
  }

  bindEvents() {
    const targetElms = this.container.getElementsByClassName("js-page-bg-elm");

    // 特定のスクロール位置で「-active」クラスを追加
    if (targetElms.length) {
      Array.prototype.forEach.call(targetElms, (elm, id) => {
        ScrollTrigger.create({
          trigger: elm,
          id: id+1,
          start: 'top center',
          toggleClass: {
            targets: elm,
            className: '-active'
          },
          markers: false,
        });
      });
    }
  }
}
