import _ from "lodash";

export default class Header {
  constructor() {
    this.container = document.getElementsByClassName("js-header")[0];
    this.lockFlg = false;
    this.menuOpenFlg = false;
    this.delayTime = 400;
    this.init();
  }

  init() {
    if (!this.container) return false;

    this.bindEvents();
  }

  /**
   * イベント処理設定
   * @return {undefined}
   */
  bindEvents() {
    const spMenuBtn = this.container.getElementsByClassName('js-header-sp-menu-btn')[0];

    // メニューボタン
    if (spMenuBtn) {
      spMenuBtn.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        if (!this.lockFlg) {
          this.lockFlg = true;
          if (spMenuBtn.classList.contains('-active')) {
            this.spCloseMenu();
          } else {
            this.spOpenMenu();
          }
        }
      });
    }
  }

  /**
   * メニューOpen
   * @return {undefined}
   */
  spOpenMenu() {
    const html = document.getElementsByTagName("html")[0];
    const menuBtn = this.container.getElementsByClassName('js-header-sp-menu-btn')[0];
    const content = this.container.getElementsByClassName('js-header-sp-menu-content')[0];

    this.menuOpenFlg = true;
    html.classList.add("-unscrollable");

    if (menuBtn) {
      menuBtn.classList.add("-active");
    }

    if (content) {
      content.classList.add("-visible");
      _.delay(() => {
        content.classList.add("-show");
      }, 1);
    }

    _.delay(() => {
      this.lockFlg = false;
    }, this.delayTime);
  }

  /**
   * メニューClose
   * @return {undefined}
   */
  spCloseMenu() {
    const html = document.getElementsByTagName("html")[0];
    const menuBtn = this.container.getElementsByClassName('js-header-sp-menu-btn')[0];
    const content = this.container.getElementsByClassName('js-header-sp-menu-content')[0];

    this.menuOpenFlg = false;
    html.classList.remove("-unscrollable");

    if (menuBtn) {
      menuBtn.classList.remove("-active");
    }

    if (content) {
      content.classList.remove("-show");
      _.delay(() => {
        content.classList.remove("-visible");
      }, this.delayTime);
    }

    _.delay(() => {
      this.lockFlg = false;
    }, this.delayTime);
  }
}
