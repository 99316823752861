import $ from "jquery";
import _ from "lodash";
import Header from './Header';
import PageTop from './PageTop';
import PageBusiness from './PageBusiness';
import PageContactForm from './PageContactForm';
import PageBg from './PageBg';
import ImageLoopScroll from './ImageLoopScroll.js';

$(() => {
  _.delay(() => {
    document.documentElement.classList.add("-render");

    if (document.getElementsByClassName("js-page-top").length) {
      // トップページ
      $(window).scrollTop(0);
    }
  }, 200);

  // Header
  if (document.getElementsByClassName("js-header").length) {
    new Header();
  }

  // PageTop
  if (document.getElementsByClassName("js-page-top").length) {
    new PageTop();
  }

  // PageBusiness
  if (document.getElementsByClassName("js-page-business").length) {
    new PageBusiness();
  }

  // PageContactForm
  if (document.getElementsByClassName("js-page-contact-form").length) {
    new PageContactForm();
  }

  // PageBg
  if (document.getElementsByClassName("js-page-bg").length) {
    new PageBg();
  }

  // ImageLoopScroll
  if (document.getElementsByClassName("js-image-loop-scroll").length) {
    new ImageLoopScroll();
  }
});
