import _ from "lodash";

export default class PageContactForm {
  constructor() {
    this.container = document.getElementsByClassName("js-page-contact-form")[0];
    this.init();
  }

  init() {
    if (!this.container) return false;

    this.bindEvents();
  }

  bindEvents() {
    // 入力エラーがあれば親要素にクラス追加
    const errorElms = this.container.getElementsByClassName("js-input-area");
    if (errorElms.length) {
      Array.prototype.forEach.call(errorElms, elm => {
        if (elm.getElementsByClassName("error")[0]) {
          elm.classList.add("-error");
        }
      });
    }

    // inputのinputModeをtelに変換
    const changeTelElms = this.container.getElementsByClassName("js-mw-wp-form-tel");
    if (changeTelElms.length) {
      Array.prototype.forEach.call(changeTelElms, elm => {
        elm.inputMode = "tel";
      });
    }
  }
}
