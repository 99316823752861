export default class ImageLoopScroll {
  constructor() {
    this.target = document.getElementsByClassName("js-image-loop-scroll");
    this.positions;
    this.animationFrameIds;
    this.startPos;
    this.init();
  }

  init() {
    if (!this.target.length) return false;

    this.positions = Array(this.target.length).fill(0);
    this.animationFrameIds = Array(this.target.length).fill(null);
    this.startPos = Array(this.target.length).fill(0);

    this.setStartPos();
    this.bindEvents();
  }

  /**
   * イベント処理設定
   * @return {undefined}
   */
  bindEvents() {
    const self = this;

    Array.prototype.forEach.call(this.target, (elm, i) => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            self.loop(i);
          } else {
            cancelAnimationFrame(self.animationFrameIds[i]);
          }
        });
      });

      observer.observe(elm);
    });

    // resize event
    const debouncedOnResize = _.debounce(() => {
      this.setStartPos();
    }, 0);
    window.addEventListener("resize", () => {
      window.requestAnimationFrame(debouncedOnResize);
    }, false);
  }

  setStartPos() {
    // 初期位置を設定
    // 最初の画像サイズ分左に移動
    Array.prototype.forEach.call(this.target, (elm, index) => {
      const liElm = elm.getElementsByClassName("js-image-loop-scroll-item")[0];
      if (liElm) {
        elm.style.left = -liElm.offsetWidth + "px";
        this.startPos[index] = -liElm.offsetWidth;
      }
    });
  }

  loop(index) {
    this.positions[index]--;

    if (this.positions[index] <= this.startPos[index]) {
      this.positions[index] = 0;
    }

    this.target[index].style.left = this.startPos[index] + this.positions[index] + "px";
    this.animationFrameIds[index] = requestAnimationFrame(() => this.loop(index));
  }
}

