import _ from "lodash";
import lottie from "lottie-web";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class PageTop {
  constructor() {
    this.container = document.getElementsByClassName("js-page-top")[0];
    this.vw = window.innerWidth;
    this.init();
  }

  init() {
    const self = this;
    if (!this.container) return false;

    this.kv("pc");
    this.kv("sp");

    _.delay(() => {
      this.bgInView();
      this.bindEvents();
    }, 800);

    window.addEventListener("load", () => {
      self.setKvSize(true);
    });
    window.addEventListener('resize', () => {
      self.setKvSize();
    });
  }

  bindEvents() {
    const kvHeadingElm = this.container.getElementsByClassName("js-top-kv-heading")[0];

    if (kvHeadingElm) {
      kvHeadingElm.classList.add('-show');
    }

    /*
    if (kvHeadingElm) {
      let mm = gsap.matchMedia();

      mm.add("(max-width: 767px)", () => {
        // sp
        ScrollTrigger.create({
          trigger: kvHeadingElm,
          start: "top-=10 top",
          end: "bottom+=380 bottom",
          toggleClass: {
            targets: kvHeadingElm,
            className: '-show'
          },
          markers: false,
        });
      });

      mm.add("(min-width: 768px)", () => {
        // pc
        ScrollTrigger.create({
          trigger: kvHeadingElm,
          start: "top-=10 top",
          end: "bottom+=200 bottom",
          toggleClass: {
            targets: kvHeadingElm,
            className: '-show'
          },
          markers: false,
        });
      });
    }
    */
  }

  setKvSize(firstFlg = false) {
    const mediaQuery = window.matchMedia("(max-width: 767px)");

    if (!firstFlg && mediaQuery.matches) {
      // sp
      // 画面幅が変わってなければ何もしない
      if (this.vw == window.innerWidth) return false;
    }
    this.vw = window.innerWidth;

    const elmPc = document.getElementById("kvBgPc");
    const elmSp = document.getElementById("kvBgSp");

    if (elmPc) {
      if (elmPc.offsetWidth && elmPc.offsetHeight) {
        const ratio = Math.max(
          window.innerWidth / elmPc.offsetWidth + 0.05,
          window.innerHeight / elmPc.offsetHeight + 0.05
        );
        elmPc.style.transform = `scale(${ratio})`;
        elmPc.style.transformOrigin = 'center center';
      }
    }

    if (elmSp) {
      if (elmSp.offsetWidth && elmSp.offsetHeight) {
        const ratio = Math.max(
          window.innerWidth / elmSp.offsetWidth,
          window.innerHeight / elmSp.offsetHeight
        );
        elmSp.style.transform = `scale(${ratio})`;
        elmSp.style.transformOrigin = 'center center';
      }
    }
  }

  kv(ua) {
    const self = this;
    let elm;
    let path;

    if (ua == "pc") {
      elm = document.getElementById("kvBgPc");
      path = "/jogatom_2023_cms/wp-content/themes/jogatom/assets/json/topkv/pc/kv_pc.json";
    } else if (ua == "sp") {
      elm = document.getElementById("kvBgSp");
      path = "/jogatom_2023_cms/wp-content/themes/jogatom/assets/json/topkv/sp/kv_sp.json";
    }

    if (!elm || path == "") return false;

    const anim = lottie.loadAnimation({
      container: elm,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: path,
      rendererSettings: {
        preserveAspectRatio: 'none'
      }
    });

    anim.addEventListener("data_ready", complete);

    function complete() {
      self.setKvSize(true);
    }
  }

  bgInView() {
    const targetElms = this.container.getElementsByClassName("js-top-bg-elm");

    // 特定のスクロール位置で「-active」クラスを追加
    if (targetElms.length) {
      Array.prototype.forEach.call(targetElms, (elm, id) => {
        ScrollTrigger.create({
          trigger: elm,
          id: id+1,
          start: 'top center',
          toggleClass: {
            targets: elm,
            className: '-active'
          },
          markers: false,
        });
      });
    }
  }
}
